
import { defineComponent } from "vue";
import { MdCard } from "@/components/md/MdCard";
import OverviewChatbotCard from "@/components/admin/ChatbotCard.vue";
import OverviewChatbotLoader from "@/components/admin/ChatbotCardLoader.vue";
import HeaderImageLeft from "@/assets/images/overview-header-left.svg";
import HeaderImageRight from "@/assets/images/overview-header-right.svg";
import { getAllBots, IChatbotResult } from "@/api/bot";

export default defineComponent({
  name: "Overview",
  components: {
    MdCard,
    OverviewChatbotCard,
    OverviewChatbotLoader,
  },
  created() {
    getAllBots().then(bots => {
      this.chatbots = bots;
    });
  },
  data() {
    return {
      chatbots: [] as IChatbotResult[],
      HeaderImageLeft,
      HeaderImageRight,
    }
  },
  methods: {
    redirectToChatbot(chatbot: IChatbotResult) {
      this.$router.push({
        path: "/bot/" + chatbot.id
      });
    }
  },
});
